import React, { useContext } from "react";

import { Box, Text, Flex } from "flicket-ui";
import useSWR from "swr";

import { Menu, Seo, Status } from "~components";
import { BaseContext } from "~context";

import { Detail } from "~components/events";
import { EmptyState } from "~components/events/events.EmptyState";
import { sdk } from "~lib/graphQL";
import { formatDate } from "~lib/helpers";

const SingleEventPage = () => {
  const { organization } = useContext(BaseContext);

  const { data, error } = useSWR(
    organization?.id ? ["singleEvent", organization?.id] : null,
    async (_: string, orgId: string) => sdk({ orgId }).singleEvent()
  );

  return (
    <>
      <Seo title="Event page" description="Event overview page for" />
      <Menu>
        {data?.currentOrganization?.singleEvent && (
          <Box
            display={{ _: "flex", md: "none" }}
            position="absolute"
            bottom="16px"
            left="16px"
            zIndex={2}
            maxW="calc(100% - 32px)"
            flexDir="column"
          >
            <Text
              fontSize={6}
              lineHeight="medium"
              fontWeight="heavy"
              color="white"
              mb="1/2"
              ellipsis
            >
              {data?.currentOrganization?.singleEvent.title}
            </Text>
            <Flex
              mr={{ _: 2, md: 4 }}
              flexDir="column"
              minW={{ _: "80px", md: "auto" }}
              w={{ _: "80px", md: "115px" }}
            >
              {data?.currentOrganization?.singleEvent.endDate &&
              formatDate(
                data?.currentOrganization?.singleEvent.startDate as string,
                "dd"
              ) !==
                formatDate(
                  data?.currentOrganization?.singleEvent.endDate as string,
                  "dd"
                ) ? (
                <Text fontSize={1} lineHeight="normal" mb="1/4" color="white">
                  {formatDate(
                    data?.currentOrganization?.singleEvent.startDate as string,
                    "dd"
                  )}{" "}
                  -{" "}
                  {formatDate(
                    data?.currentOrganization?.singleEvent.endDate as string,
                    "dd MMM"
                  )}
                </Text>
              ) : (
                <>
                  <Text fontSize={1} lineHeight="normal" mb="1/4" color="white">
                    {formatDate(
                      data?.currentOrganization?.singleEvent
                        .startDate as string,
                      "dd MMM"
                    )}
                  </Text>
                  <Text
                    color={{ _: "N800", md: "N600" } as any}
                    mt={{ _: 0, md: "1/2" }}
                    lineHeight={{ _: "18px" as any, md: "normal" }}
                    fontSize={{ _: 2, md: 3 }}
                    fontWeight={{ _: "medium", md: "regular" }}
                    ellipsis
                  >
                    {formatDate(
                      data?.currentOrganization?.singleEvent
                        .startDate as string,
                      "E, p"
                    )}
                  </Text>
                </>
              )}
            </Flex>
            <Text fontSize={1} lineHeight="normal" color="white">
              {data?.currentOrganization?.singleEvent.venue?.name},{" "}
              {data?.currentOrganization?.singleEvent.venue?.address?.city}
            </Text>
          </Box>
        )}
      </Menu>
      <Status loading={!data && !error} error={error}>
        {data?.currentOrganization?.singleEvent ? (
          <>
            {/** @ts-expect-error type mismatch */}
            <Detail event={data?.currentOrganization?.singleEvent} />
          </>
        ) : (
          <EmptyState />
        )}
      </Status>
    </>
  );
};

export default SingleEventPage;
